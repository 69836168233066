import React, {
  useState, useCallback, useMemo
} from 'react';

import SingleModal from './SingleModal';
import Button from './Button';
import * as Helpers from '../helpers';

function CompleteWithdrawalFt(props) {
  const {
    loading, getHistoryData, permissions, item, actionID, completeWithdrawal, setActionID,
  } = props;

  const loadingCompleted = useMemo(() => loading.some(url => url === '/admin/withdrawal/complete'), [loading]);
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);
  const handleWithdrawalModalOpen = useCallback(() => setWithdrawalModalOpen(true), []);
  const handleWithdrawalModalClose = useCallback(() => {
    setWithdrawalModalOpen(false);
  }, []);
  const handleWithdrawalCompleteSubmit = useCallback((event, withdrawal_id) => {
    event.preventDefault();
    setActionID(withdrawal_id);
    if (Helpers.permission(['update-withdrawal-status-complete'], permissions)) {
      handleWithdrawalModalOpen();
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-withdrawal-status-complete]');
    }
  }, [handleWithdrawalModalOpen, permissions, setActionID]);

  const withdrawalComplete = useCallback(() => {
    completeWithdrawal(
      actionID,
      res => {
        getHistoryData();
        Helpers.notification.success(res.message);
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
    handleWithdrawalModalClose();
  }, [actionID, completeWithdrawal, getHistoryData, handleWithdrawalModalClose]);

  return (
    <>
      <Button
        type="info"
        loading={item.withdrawal_id === actionID && loadingCompleted}
        onClick={e => handleWithdrawalCompleteSubmit(e, item.withdrawal_id)}
      >
        Complete
      </Button>

      {/* withdrawal complete modal */}
      <SingleModal
        modalName="completeWithdrawal"
        modalOpen={withdrawalModalOpen}
        modalTitle="Are you sure you want mark this withdrawal as completed?"
        modalSubmit={withdrawalComplete}
        modalClose={handleWithdrawalModalClose}
      />
    </>
  );
}

CompleteWithdrawalFt.props = {};

export default CompleteWithdrawalFt;
