import React, {
  useState, useCallback, useMemo
} from 'react';
import {
  Box
} from '@mui/material';

import SingleModal from './SingleModal';
import Button from './Button';
import * as Helpers from '../helpers';

function ApproveWithdrawalFt(props) {
  const {
    loading, getHistoryData, permissions, item, actionID, setActionID, approveWithdrawal,
  } = props;

  const [approveOrReject, setApproveOrReject] = useState('reject');
  const [approveStep, setApproveStep] = useState(1);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const loadingApprove = useMemo(() => loading.some(url => url === '/admin/withdrawal/approve'), [loading]);

  const handleApproveModalOpen = useCallback((event, withdrawal_id) => {
    event.preventDefault();
    setActionID(withdrawal_id);
    if (Helpers.permission(['update-withdrawal-approve'], permissions) || Helpers.permission(['update-withdrawal-reject'], permissions)) {
      setApproveModalOpen(true);
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-withdrawal-approve, update-withdrawal-reject]');
    }
  }, [permissions, setActionID]);

  const handleSetApprovalStep = useCallback(step => {
    setApproveStep(step || 2);
  }, []);

  const handleApproveModalClose = useCallback(() => {
    setApproveOrReject('reject');
    handleSetApprovalStep(1);
    setApproveModalOpen(false);
  }, [handleSetApprovalStep]);

  const handleSetApproveOrReject = useCallback(decision => () => {
    setApproveOrReject(decision);
    handleSetApprovalStep(2);
  }, [handleSetApprovalStep]);

  const handleApprove = useCallback(() => {
    // add permissions check
    if (
      (approveOrReject === 'approve' && Helpers.permission(['update-withdrawal-approve'], permissions))
        || (approveOrReject !== 'approve' && Helpers.permission(['update-withdrawal-reject'], permissions))
    ) {
      approveWithdrawal(
        actionID,
        approveOrReject === 'approve',
        res => {
          Helpers.notification.success(res.message);
          getHistoryData();
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    } else {
      Helpers.notification.error(`You do not have the necessary permission(s): ${approveOrReject === 'approve' ? '[update-withdrawal-approve]' : '[update-withdrawal-reject]'}`);
    }
    handleSetApprovalStep(1);
    handleApproveModalClose();
  }, [actionID, approveOrReject, approveWithdrawal, permissions, getHistoryData, handleApproveModalClose, handleSetApprovalStep]);

  return (
    <>
      <Button
        type="info"
        loading={item.withdrawal_id === actionID && loadingApprove}
        onClick={e => handleApproveModalOpen(e, item.withdrawal_id)}
      >
        Approve
      </Button>

      {/* withdrawal approve/reject modal */}
      <SingleModal
        modalName="approve"
        modalOpen={approveModalOpen}
        modalTitle={
                  approveStep === 1
                    ? 'Do you want to APPROVE or REJECT this withdrawal?'
                    : `Are you sure you want to ${approveOrReject.toUpperCase()} this withdrawal?`
                }
        defaultButtons={approveStep === 1
          ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                mt: 3,
              }}
            >
              <Button
                onClick={handleSetApproveOrReject('reject')}
                type="secondary"
              >
                Reject
              </Button>
              <Button
                onClick={handleSetApproveOrReject('approve')}
                type="success"
              >
                Approve
              </Button>
            </Box>
          )
          : false}
        actionButtonText="Yes"
        modalSubmit={handleApprove}
        modalClose={handleApproveModalClose}
      />
    </>
  );
}

ApproveWithdrawalFt.props = {};

export default ApproveWithdrawalFt;
