import React, { Component } from 'react';

import {
  Typography,
  Card,
  CardActions,
  CardContent,
  Button as MuiButton
} from '@mui/material';

class UserCard extends Component {
  state = {
    showMore: false,
  };

  toggle = () => {
    const { showMore } = this.state;
    this.setState({ showMore: !showMore });
  };

  render() {
    const { history, rates, formatDate } = this.props;
    const { showMore } = this.state;

    return (
      <Card sx={{ m: 3, minWidth: 275 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            User Details
          </Typography>
          <Typography>
            First Name:
            {' '}
            <span>{history.user[0]?.firstname}</span>
            <br />
            Last Name:
            {' '}
            <span>{history.user[0]?.lastname}</span>
            <br />
            Email:
            {' '}
            <span>{history.user[0]?.email}</span>
            <br />
            Mobile:
            {' '}
            <span>
              {history.user[0]?.mobile}
            </span>
            <br />
            Card:
            {' '}
            <span>{history.user[0]?.card ? 'YES' : 'NO'}</span>
            <br />
            Token:
            {' '}
            <span>{history.user[0]?.token ? 'YES' : 'NO'}</span>
            <br />
            <span style={{ display: showMore ? 'block' : 'none' }}>
              Balance:
              {' '}
              <span>{history.user[0]?.balance}</span>
              <br />
              Date:
              {' '}
              <span>{history.user[0]?.date}</span>
              <br />
              Current Minimum Debt (Total):
              {' '}
              <span>{history.user[0]?.info.minimum.currentMinimum.amount}</span>
              <br />
              Current Minimum Debt (Interest):
              {' '}
              <span>{history.user[0]?.info.minimum.currentMinimum.interest}</span>
              <br />
              Current Minimum Debt (Principal):
              {' '}
              <span>{history.user[0]?.info.minimum.currentMinimum.principal}</span>
              <br />
              Current Total Debt:
              {' '}
              <span>{history.user[0]?.info.total.currentDebt}</span>
              <br />
              Future Minimum Debt (Total):
              {' '}
              <span>{history.user[0]?.info.minimum.futureMinimum.amount}</span>
              <br />
              Future Minimum Debt (Interest):
              {' '}
              <span>{history.user[0]?.info.minimum.futureMinimum.interest}</span>
              <br />
              Future Minimum Debt (Principal):
              {' '}
              <span>{history.user[0]?.info.minimum.futureMinimum.principal}</span>
              <br />
              Future Total Debt:
              {' '}
              <span>{history.user[0]?.info.total.futureDebt}</span>
              <br />
              Next Due Date:
              {' '}
              <span>{(history.withdrawal.length === 0 && history.user[0]?.balance <= 0) || history.user[0]?.info.minimum.futureMinimum.date === '' ? '-' : formatDate(history.user[0]?.info.minimum.futureMinimum.date)}</span>
              <br />
              Blacklist:
              {' '}
              <span>{history.user[0]?.blacklist === null ? 'NO' : 'YES'}</span>
              <br />
              Buy Rate:
              {' '}
              <span>{rates.buy}</span>
              <br />
              Sell rate:
              {' '}
              <span>{rates.sell}</span>
            </span>
          </Typography>
        </CardContent>
        <CardActions>
          <MuiButton size="small" onClick={this.toggle}>{ showMore ? 'Hide' : 'Show More' }</MuiButton>
        </CardActions>
      </Card>
    );
  }
}

UserCard.props = {};

export default UserCard;
