import React, {
  useState, useEffect, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MuiButton from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

import {
  Loading, Menu, Button, SingleModal
} from '../../components';

import * as Helpers from '../../helpers';

import './Defaults.css';

const drawerWidth = 240;

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const yesterdayMonth = yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`;
const tomorrowMonth = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
const yesterdayDay = yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`;
const tomorrowDay = tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
const toDate = `${tomorrow.getFullYear()}/${tomorrowMonth}/${tomorrowDay}`;
const fromDate = `${yesterday.getFullYear()}/${yesterdayMonth}/${yesterdayDay}`;

Helpers.token.set(toDate, 'date:to');
Helpers.token.set(fromDate, 'date:from');

const todayDate = new Date();
const todayDay = todayDate.getDate();

function Defaults(props) {
  const {
    clearAll,
    type,
    admin: {
      defaults: all, email, loading, permissions,
    },
    getDefaults,
    liquidate,
    refresh,
    getSingleLoan,
  } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(Helpers.token.get('defaults:rows_per_page'), 10) || 10);
  const [isTyping, setIsTyping] = useState(false);
  const [defaultMonths, setDefaultMonths] = useState(3);
  const [actionID, setActionID] = useState(0);
  const [liquidationNote, setLiquidationNote] = useState('');
  const [liquidatioModalOpen, setLiquidationModalOpen] = useState(false);
  const [applicationId, setApplicationId] = useState(0);
  const [liquidateStep, setLiquidateStep] = useState(1);
  const [liquidationAmount, setLiquidationAmount] = useState(0);
  const [totalLeftToLiquidate, setTotalLeftToLiquidate] = useState(0);
  const [userPortfolio, setUserPortfolio] = useState(0);
  const [loanLockOutstandingAmount, setLoanLockOutstandingAmount] = useState(0);
  const [loanLockCollateralAmount, setLoanLockCollateralAmount] = useState(0);
  const [strictMode, setStrictMode] = useState(true);

  const loadingTable = useMemo(() => loading.some(url => url === `/admin/defaults?application_id=${applicationId}&limit=${rowsPerPage}&offset=${page * rowsPerPage}&interval=${defaultMonths}&strictMode=${strictMode}`), [applicationId, defaultMonths, loading, page, rowsPerPage, strictMode]);
  const loadingPortfolio = useMemo(() => loading.some(url => url === '/admin/portfolio'), [loading]);
  const loadingLiquidate = useMemo(() => loading.some(url => url === '/liquidate'), [loading]);
  const loadingLock = useMemo(() => loading.some(url => url === `/loan/${actionID}?lock=true`), [actionID, loading]);

  // const matches = useMediaQuery('(min-width: 768px)');

  const getDefaultsData = useCallback((clear = true) => {
    if (clear) {
      clearAll();
    }

    setTimeout(() => {
      getDefaults(
        applicationId,
        rowsPerPage,
        page * rowsPerPage,
        defaultMonths,
        strictMode,
        res => {
          Helpers.notification.success(res.message);
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    }, 1000);
  }, [applicationId, clearAll, defaultMonths, getDefaults, page, rowsPerPage, strictMode]);

  useEffect(() => {
    getDefaultsData();
  }, [getDefaultsData]);

  useEffect(() => {
    setTimeout(() => isTyping && setIsTyping(false), 5000);
  }, [isTyping]);

  const reload = useCallback(() => {
    window.location = `/${type}`;
    return null;
  }, [type]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(+event.target.value);
    Helpers.token.set(+event.target.value, 'defaults:rows_per_page');
    setPage(0);
  }, []);

  const handleSetDefaultMonths = useCallback(event => {
    setDefaultMonths(parseInt(event.target.value, 10));
  }, []);

  const handleSetApplicationId = useCallback(event => {
    setApplicationId(parseInt(event.target.value, 10));
  }, []);

  const handleSetLiquidateStep = useCallback(step => {
    setLiquidateStep(step || 2);
  }, []);

  const handleSetLiquidationAmount = useCallback(event => {
    setLiquidationAmount(event.target.value);
  }, []);

  const handleLiquidationAmountSubmit = useCallback(() => {
    // First check permission
    if (!Helpers.permission(['create-liquidation-loan.id'], permissions)) {
      Helpers.notification.error('You do not have the necessary permission(s): [create-liquidation-loan.id]');
      return;
    }

    // Then check amount validity
    if (liquidationAmount <= 0) {
      Helpers.notification.error('Liquidation amount must be greater than 0');
      return;
    }

    // Finally check if amount exceeds total
    const excess = liquidationAmount > totalLeftToLiquidate;
    if (excess) {
      Helpers.notification.error('You cannot liquidate more than total left to liquidate.');
      return;
    }

    // If all checks pass, proceed to next step
    handleSetLiquidateStep(2);
  }, [handleSetLiquidateStep, liquidationAmount, permissions, totalLeftToLiquidate]);

  const handleLiquidationModalOpen = useCallback(() => {
    setLiquidationNote('');
    setLiquidationModalOpen(true);
  }, []);

  const handleLiquidationModalClose = useCallback(() => {
    setUserPortfolio(0);
    setLoanLockOutstandingAmount(0);
    setLoanLockCollateralAmount(0);
    handleSetLiquidateStep(1);
    setLiquidationModalOpen(false);
  }, [handleSetLiquidateStep]);

  const handleSetLiquidationNote = useCallback(event => {
    setLiquidationNote(event.target.value);
  }, []);

  const handlePortfolioLoad = useCallback((user_id, application_id) => {
    refresh(
      user_id,
      application_id,
      res => {
        setUserPortfolio(res.data.real_amount);
        Helpers.notification.success(res.message);
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
  }, [refresh]);

  const handleLockLoad = useCallback(loan_id => {
    getSingleLoan(
      loan_id,
      true,
      res => {
        setLoanLockOutstandingAmount(res.data.loans[0].lock.outstanding_amount);
        setLoanLockCollateralAmount(res.data.loans[0].lock.collateral_amount);
        Helpers.notification.success(res.message);
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
  }, [getSingleLoan]);

  const handleLiquidateLoanButton = useCallback((event, loan_id, total_left_to_liquidate, user_id, application_id) => {
    event.preventDefault();
    setActionID(loan_id);
    setTotalLeftToLiquidate(total_left_to_liquidate);
    setLiquidationAmount(total_left_to_liquidate);
    if (Helpers.permission(['create-liquidation-loan.id'], permissions)) {
      handlePortfolioLoad(user_id, application_id);
      handleLockLoad(loan_id);
      handleLiquidationModalOpen();
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-liquidation-loan.id]');
    }
  }, [permissions, handleLiquidationModalOpen, handleLockLoad, handlePortfolioLoad]);

  const handleLiquidateLoan = useCallback(() => {
    liquidate(
      'DEFAULTED',
      actionID,
      liquidationAmount,
      liquidationNote,
      res => {
        Helpers.notification.success(res.message);
        setTimeout(() => {
          getDefaultsData();
        }, 3000);
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
    setLiquidationAmount(0);
    setLiquidationNote('');
    handleSetLiquidateStep(1);
    handleLiquidationModalClose();
  }, [actionID, getDefaultsData, handleLiquidationModalClose, handleSetLiquidateStep, liquidate, liquidationAmount, liquidationNote]);

  const handleSetStrictMode = useCallback(() => {
    setStrictMode(!strictMode);
  }, [strictMode]);

  all.forEach(item => {
    const { disbursed, last_repayment_date } = item;
    const todayTime = todayDate.getTime() - todayDate.setHours(0, 0, 0, 0);

    const disbursedDate = new Date(last_repayment_date || disbursed);
    const disbursedDay = disbursedDate.getDate();
    const disbursedTime = disbursedDate.getTime() - disbursedDate.setHours(0, 0, 0, 0);

    const diffInMonths = todayDate.getMonth() - disbursedDate.getMonth() + (12 * (todayDate.getFullYear() - disbursedDate.getFullYear()));
    const realDiffInMonths = diffInMonths <= 0
      ? 0
      : todayDay > disbursedDay || (todayDay === disbursedDay && todayTime >= disbursedTime)
        ? diffInMonths
        : diffInMonths - 1;

    // eslint-disable-next-line no-param-reassign
    item.default_months = realDiffInMonths;
    // eslint-disable-next-line no-param-reassign
    item.liquidate = 'N/A';
  });

  const keys = all.length > 0 ? Object.keys(all[0]) : [];

  let currentCount = 0;
  let currentUserId = -1;

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                flexDdirection: 'row',
                mr: 2,
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { sm: 'block', xs: 'none' }, flexGrow: 1 }}
            >
              carrot
            </Typography>
          </Toolbar>
        </AppBar>
        {/* <Toolbar /> */}
      </Box>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Menu
          {...props}
          email={email}
          open={mobileOpen}
          width={drawerWidth}
          toggle={handleDrawerToggle}
        />

        {loadingTable ? <Loading size="big" /> : (
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            style={{ maxWidth: '100%' }}
          >
            {/* <Toolbar /> */}
            <Typography
              sx={{
                fontSize: 'h5.fontSize',
                m: 0.25,
                p: 0.5,
              }}
              style={{ marginTop: 50 }}
            >
              {Helpers.capitalizeFirstLetter(type)}
              {' '}
              (Page
              {' '}
              {page + 1}
              )
            </Typography>

            <FormControl fullWidth sx={{ marginRight: 0, maxWidth: 120, mt: '19px' }}>
              <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', margin: 'auto' }}>
                Strict Mode
              </div>
              <Switch
                aria-label="Switch demo"
                defaultChecked
                sx={{ margin: 'auto' }}
                checked={strictMode}
                onChange={handleSetStrictMode}
              />
            </FormControl>

            <FormControl fullWidth sx={{ marginRight: 20, maxWidth: 120, mt: '19px' }}>
              <InputLabel id="demo-simple-select-label">Months</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={defaultMonths}
                label="Months"
                autoWidth
                onChange={handleSetDefaultMonths}
              >
                {[0, 1, 2, 3].map((sta, i) => (
                  <MenuItem key={i} value={sta}>
                    {`${sta} ${!strictMode && sta > 0 ? 'or more' : ''} ${sta >= 1 ? 'months' : 'month'}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginRight: 20, maxWidth: 120, mt: '19px' }}>
              <InputLabel id="demo-simple-select-label">Application_id</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={applicationId}
                label="Application_id"
                autoWidth
                onChange={handleSetApplicationId}
              >
                {
                  [0, 1, 2].map((sta, i) => <MenuItem key={i} value={sta}>{sta === 0 ? 'all' : sta}</MenuItem>)
                }
              </Select>
            </FormControl>

            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: {
                  lg: 'row',
                  md: 'row',
                  sm: 'column',
                  xs: 'column',
                },
                justifyContent: 'right',
                m: 2,
                maxWidth: '100%',
              }}
            >
              <Box
                sx={{
                  display: type === 'user' || type === 'portfolio' ? 'none' : 'block',
                  ml: 2,
                }}
              >

                <CSVLink
                  data={all}
                  filename={`${type}-pg-${page + 1}.csv`}
                  className="btn btn-primary csvButton"
                >
                  {Helpers.capitalizeFirstLetter('Export as CSV')}
                </CSVLink>
              </Box>
            </Box>

            <Paper sx={{ overflow: 'hidden', width: '100%' }}>
              <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table stickyHeader aria-label="sticky table">
                  <caption>
                    {
                      all.length === 0
                        ? (
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              m: 2,
                            }}
                          >
                            <span>
                              There are no
                              {' '}
                              {type}
                              s for the selected period.
                            </span>
                            <MuiButton variant="outlined" color="info" size="medium" onClick={reload}>
                              Refresh
                            </MuiButton>
                          </Box>
                        ) : `These are ${type}s for the selected period only.`
                    }
                  </caption>

                  <TableHead>
                    <TableRow>
                      {all.length !== 0 && (
                      <TableCell
                        align="center"
                        style={{ minWidth: 150 }}
                      >
                        {Helpers.capitalizeFirstLetter('s/n')}
                      </TableCell>
                      )}
                      {all.length === 0 || loadingTable ? null : keys.map((key, i) => {
                        const heading = key;
                        return (
                          <TableCell
                            key={i}
                            align="center"
                            style={{ minWidth: 150 }}
                          >
                            {Helpers.capitalizeFirstLetter(heading)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {all.length === 0 ? null : loadingTable
                      ? <Loading size="big" />
                      : all
                        .map((item, i) => {
                          if (item.user_id !== currentUserId) {
                            currentCount += 1;
                            currentUserId = item.user_id;
                          }
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                              <TableCell align="center" title={currentCount}>
                                {currentCount}
                              </TableCell>
                              {keys.map((key, j) => {
                                const val = item[key];
                                if (key === 'interest_to_liquidate' || key === 'principal_to_liquidate' || key === 'total_to_liquidate') {
                                  return (
                                    <TableCell key={j} align="center" title={item.default_months >= 3 ? val : 0}>
                                      {item.default_months >= 3 ? val : 0}
                                    </TableCell>
                                  );
                                }
                                if (/_id$/.test(key) && !/application/.test(key)) {
                                  const pageName = key.split('_')[0];
                                  return (
                                    <TableCell key={j} align="center" title={`View ${pageName} details`}>
                                      <Link to={`${pageName}/${val}`}>{val}</Link>
                                    </TableCell>
                                  );
                                }
                                if (key === 'last_repayment_date' && !val) {
                                  return (
                                    <TableCell key={j} align="center" title={typeof val === 'object' || typeof val === 'boolean' ? '' : val}>
                                      {'<never repaid>'}
                                    </TableCell>
                                  );
                                }
                                if (key === 'liquidate' && item.default_months >= 3 && item.total_left_to_liquidate > 0) {
                                  return (
                                    <TableCell key={j} align="center" title="liquidate">
                                      <Button
                                        type="error"
                                        loading={(item.loan_id === actionID && loadingLiquidate)}
                                        onClick={e => handleLiquidateLoanButton(e, item.loan_id, item.total_left_to_liquidate, item.user_id, item.application_id)}
                                        key={j}
                                      >
                                        liquidate
                                      </Button>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell key={j} align="center" title={typeof val === 'object' || typeof val === 'boolean' ? '' : val}>
                                    {val}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}

                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 75, 100, 500, 1000, 2000]}
                component="div"
                count={all.length < rowsPerPage ? (page * rowsPerPage) + all.length : (page * rowsPerPage) + rowsPerPage + 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            </Paper>
          </Box>
        )}
      </Box>
      <Box>
        {/* liquidation modal */}
        <div>
          <SingleModal
            modalName="liquidation"
            modalOpen={liquidatioModalOpen}
            modalTitle={
              liquidateStep === 1
                ? (
                  <div className="liquidationDetails">
                    <div className="liquidationDetailsTitle">
                      How much do you want to liquidate for this loan?
                    </div>
                    <div>
                      Portfolio:
                      {' '}
                      {userPortfolio}
                      {' '}
                      cents;
                    </div>
                    <div>
                      Total left to liquidate:
                      {' '}
                      {totalLeftToLiquidate}
                      {' '}
                      cents;
                    </div>
                    <div>
                      Outstanding amount from Partner:
                      {' '}
                      {loanLockOutstandingAmount}
                      {' '}
                      cents;
                    </div>
                    <div>
                      Collateral amount from Partner:
                      {' '}
                      {loanLockCollateralAmount}
                      {' '}
                      cents;
                    </div>
                  </div>
                )
                : `
                  Are you sure you want to liquidate ${liquidationAmount}
                  cents for loan_id ${actionID}
                  ?
                `
            }
            modalContent={(loadingPortfolio || loadingLock) && <Loading size="small" />}
            modalInput
            inputPlaceholder={
              liquidateStep === 1
                ? 'Enter amount to liquidate'
                : 'Enter liquidation Note'
            }
            inputValue={
              liquidateStep === 1
                ? liquidationAmount
                : liquidationNote
            }
            onInputChange={
              liquidateStep === 1
                ? handleSetLiquidationAmount
                : handleSetLiquidationNote
            }
            actionButtonText={
              liquidateStep === 1
                ? 'Submit'
                : 'Yes'
            }
            actionButtonDisabled={
              liquidateStep === 1
                ? liquidationAmount < 1
                : liquidationNote === ''
            }
            modalSubmit={
              liquidateStep === 1
                ? handleLiquidationAmountSubmit
                : handleLiquidateLoan
              }
            modalClose={handleLiquidationModalClose}
          />
        </div>
      </Box>
    </div>
  );
}

Defaults.propTypes = {
  type: PropTypes.string,
};

export default Defaults;
