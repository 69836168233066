import React, {
  useState, useCallback, useMemo
} from 'react';

import SingleModal from './SingleModal';
import Button from './Button';
import * as Helpers from '../helpers';

function ApproveAccountFt(props) {
  const {
    loading, getHistoryData, permissions, item, actionID, setActionID, approveAccount,
  } = props;

  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const loadingApprove = useMemo(() => loading.some(url => url === '/admin/account/approve'), [loading]);

  const handleApproveModalOpen = useCallback((event, account_id) => {
    event.preventDefault();
    setActionID(account_id);
    if (Helpers.permission(['update-account-status'], permissions)) {
      setApproveModalOpen(true);
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-account-status]');
    }
  }, [permissions, setActionID]);

  const handleApproveModalClose = useCallback(() => {
    setApproveModalOpen(false);
  }, []);

  const handleApprove = useCallback(() => {
    // add permissions check
    if (
      (Helpers.permission(['update-account-status'], permissions))
    ) {
      approveAccount(
        actionID,
        res => {
          Helpers.notification.success(res.message);
          getHistoryData();
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-account-status]');
    }
    handleApproveModalClose();
  }, [actionID, approveAccount, permissions, getHistoryData, handleApproveModalClose]);

  return (
    <>
      <Button
        type="info"
        loading={item.account_id === actionID && loadingApprove}
        onClick={e => handleApproveModalOpen(e, item.account_id)}
      >
        Approve
      </Button>

      {/* withdrawal approve/reject modal */}
      <SingleModal
        modalName="approve-account"
        modalOpen={approveModalOpen}
        modalTitle="Are you sure you want to APPROVE this beneficiary account?"
        actionButtonText="Yes"
        closeButtonText="Cancel"
        modalSubmit={handleApprove}
        modalClose={handleApproveModalClose}
      />
    </>
  );
}

ApproveAccountFt.props = {};

export default ApproveAccountFt;
