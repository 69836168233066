import React, {
  useState, useCallback, useMemo
} from 'react';
import {
  Box
} from '@mui/material';
import SingleModal from './SingleModal';
import Button from './Button';
import * as Helpers from '../helpers';

function ChargeUserFt(props) {
  const {
    loading, history, getHistoryData, permissions, id, chargeUser,
  } = props;

  const [chargeAmount, setChargeAmount] = useState(0);
  const [chargeStep, setChargeStep] = useState(1);
  const [chargeModalOpen, setChargeModalOpen] = useState(false);
  const loadingCharge = useMemo(() => loading.some(url => url === '/admin/charge'), [loading]);

  const handleChargeModalOpen = useCallback(() => {
    setChargeAmount(0);
    if (Helpers.permission(['create-repayment-card'], permissions)) {
      setChargeModalOpen(true);
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-repayment-card]');
    }
  }, [permissions]);

  const handleSetChargeStep = useCallback(step => {
    setChargeStep(step || 2);
  }, []);

  const handleChargeModalClose = useCallback(() => {
    handleSetChargeStep(1);
    setChargeModalOpen(false);
  }, [handleSetChargeStep]);

  const handleSetChargeAmount = useCallback(event => {
    setChargeAmount(event.target.value);
  }, []);

  const handleCharge = useCallback(() => {
    // add permissions check
    if (Helpers.permission(['create-repayment-card'], permissions)) {
      const { user_id } = history.user[0];
      const total_debt = history.user[0]?.info?.minimum?.currentMinimum?.amount
          || history.user[0]?.info?.total.currentDebt
          || 0;
      if (chargeAmount <= parseInt(total_debt, 10)) {
        chargeUser(
          user_id,
          chargeAmount,
          res => {
            Helpers.notification.success(res.message);
            const val = JSON.parse(JSON.stringify(res.data.details[0]))[Number(user_id)];
            if (val.amount) {
              const { amount } = val;
              Helpers.notification.success(`User was charged ${amount} cents ($${amount / 100}).`);
            }
            getHistoryData();
          },
          err => {
            Helpers.notification.error(err.message);
          }
        );
      } else {
        Helpers.notification.error('You cannot charge a user more than total debt.');
      }
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-repayment-card]');
    }
    handleSetChargeStep(1);
    handleChargeModalClose();
  }, [permissions, chargeAmount, chargeUser, getHistoryData, handleChargeModalClose, handleSetChargeStep, history.user]);

  return (
    <>
      <Box
        sx={{
          alignSelf: 'end',
          display: 'block',
          m: '24px',
        }}
      >
        <Button
          type="success"
          loading={loadingCharge}
          onClick={handleChargeModalOpen}
        >
          Charge
        </Button>
      </Box>

      {/* Charge modal */}
      <SingleModal
        modalName="charge"
        modalOpen={chargeModalOpen}
        modalTitle={
                chargeStep === 1
                  ? 'How much do you want to charge this user?'
                  : `
                    Are you sure you want to charge
                    \n
                    ${chargeAmount}
                    \n
                    cents ($
                    ${chargeAmount / 100}
                    ) from user
                    \n
                    ${id}
                    ?
                  `
              }
        modalContent=""
        modalInput={chargeStep === 1}
        inputPlaceholder="Enter amount to charge"
        inputValue={chargeAmount}
        onInputChange={handleSetChargeAmount}
        actionButtonText={chargeStep === 1 ? 'Submit' : 'Yes'}
        actionButtonDisabled={chargeAmount < 1}
        modalSubmit={chargeStep === 1 ? handleSetChargeStep : handleCharge}
        modalClose={handleChargeModalClose}
      />
    </>
  );
}

ChargeUserFt.props = {};

export default ChargeUserFt;
