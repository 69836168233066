import React from 'react';

import Signin from './Signin';
import Dashboard from './Dashboard';
import TableList from './TableList';
import NotFound from './NotFound';
import History from './History';
import Application from './Application';
import Defaults from './Defaults';
import Rates from './Rates';
import Permissions from './Permissions';
import DuplicateList from './DuplicateList';

const baseRoutes = {
  private: [
    {
      component: props => <Dashboard {...props} type="dashboard" />,
      exact: true,
      path: '/',
    },
    {
      component: props => <Dashboard {...props} type="dashboard" />,
      exact: true,
      path: '/dashboard',
    },
    {
      component: props => <TableList {...props} type="repayment" />,
      exact: true,
      path: '/repayment',
    },
    {
      component: props => <TableList {...props} type="withdrawal" />,
      exact: true,
      path: '/withdrawal',
    },
    {
      component: props => <TableList {...props} type="loan" />,
      exact: true,
      path: '/loan',
    },
    {
      component: props => <TableList {...props} type="portfolio" />,
      exact: true,
      path: '/portfolio',
    },
    {
      component: props => <TableList {...props} type="user" />,
      exact: true,
      path: '/user',
    },
    {
      component: props => <TableList {...props} type="liquidation" />,
      exact: true,
      path: '/liquidation',
    },
    {
      component: props => <Application {...props} />,
      exact: true,
      path: '/application',
    },
    {
      component: History,
      exact: true,
      path: '/:table/:id',
    },
    {
      component: props => <Defaults {...props} type="defaults" />,
      exact: true,
      path: '/default',
    },
    {
      component: props => <Rates {...props} />,
      exact: true,
      path: '/rate',
    },
    {
      component: props => <Permissions {...props} />,
      exact: true,
      path: '/permission',
    },
    {
      component: props => <DuplicateList {...props} type="repayment" />,
      exact: true,
      path: '/repayment-duplicate',
    },
  ],
  public: [
    {
      component: Signin,
      exact: true,
      path: '/',
    },
    {
      component: Signin,
      exact: true,
      path: '/signin',
    },
    {
      component: NotFound,
      exact: true,
      path: '/404',
    },
  ],
};

export default baseRoutes;
