import React, {
  useState, useCallback, useMemo
} from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';
import SingleModal from './SingleModal';
import Button from './Button';
import * as Helpers from '../helpers';

function BlacklistFt(props) {
  const {
    loading, history, blacklist, getHistoryData, permissions, id, blacklistActions, setBlacklistActions,
  } = props;

  const [blacklistReason, setBlacklistReason] = useState('');
  const [blacklistModalOpen, setBlacklistModalOpen] = useState(false);
  const loadingBlacklist = useMemo(() => loading.some(url => url === '/admin/blacklist'), [loading]);

  const handleBlacklistModalOpen = useCallback(() => {
    setBlacklistReason(history.user?.[0]?.blacklist?.reason || '');
    setBlacklistModalOpen(true);
  }, [history.user]);

  const handleBlacklistModalClose = useCallback(() => {
    setBlacklistModalOpen(false);
  }, []);

  const handleSetBlacklistReason = useCallback(event => {
    setBlacklistReason(event.target.value);
  }, []);

  const handleBlacklist = useCallback(() => {
    // add permissions check
    if (Helpers.permission(['update-user-blacklist'], permissions)) {
      blacklist(
        id,
        blacklistReason,
        blacklistActions,
        res => {
          Helpers.notification.success(res.message);
          getHistoryData();
          setBlacklistReason('None');
        },
        () => {}
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-user-blacklist]');
    }
    handleBlacklistModalClose();
  }, [permissions, handleBlacklistModalClose, blacklist, id, blacklistReason, blacklistActions, getHistoryData]);

  const handleBlacklistActionToggle = useCallback(e => {
    setBlacklistActions({
      ...blacklistActions,
      [e.target.name]: e.target.checked,
    });
  }, [blacklistActions, setBlacklistActions]);

  return (
    <>
      <Box
        sx={{
          alignSelf: 'end',
          display: 'block',
          m: '24px',
        }}
      >
        <Button
          type="secondary"
          loading={loadingBlacklist}
          onClick={handleBlacklistModalOpen}
        >
          {history.user[0]?.blacklist === null ? 'Blacklist' : 'Whitelist'}
        </Button>
      </Box>

      {/* blacklist modal */}
      <SingleModal
        modalName="Blacklist"
        modalOpen={blacklistModalOpen}
        modalTitle="Are you sure you want to update these blacklist actions for this user?"
        modalContent={(
          <FormGroup aria-label="position" column="true" sx={{ maxWidth: 120, ml: 0 }}>
            {(Object.keys(blacklistActions) || []).map((item, i) => (
              <FormControlLabel
                key={i}
                labelPlacement="start"
                label={item}
                sx={{ fontSize: '12px' }}
                control={(
                  <Switch
                    name={item}
                    size="small"
                    aria-label="Switch demo"
                    checked={blacklistActions[item]}
                    onChange={handleBlacklistActionToggle}
                  />
              )}
              />
            ))}
          </FormGroup>

          )}
        modalInput={Object.keys(blacklistActions).length > 0}
        inputPlaceholder="Enter Reason for Blacklist"
        inputValue={blacklistReason}
        onInputChange={handleSetBlacklistReason}
        actionButtonText="Yes"
        closeButtonText="Cancel"
        actionButtonDisabled={blacklistReason === ''}
        modalSubmit={handleBlacklist}
        modalClose={handleBlacklistModalClose}
      />
    </>
  );
}

BlacklistFt.props = {};

export default BlacklistFt;
