import React, {
  useState, useCallback, useMemo
} from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
import Button from './Button';
import SingleModal from './SingleModal';
import Loading from './Loading';
import * as Helpers from '../helpers';

function ClearLockFt(props) {
  const {
    getSingleLoan, item, permissions, clearLoanLock, loading,
  } = props;

  const loadingClearLock = useMemo(() => loading.some(url => url === '/admin/loan/clearlock'), [loading]);
  const [getLockModalOpen, setGetLockModalOpen] = useState(false);
  const [clearLockModalOpen, setClearLockModalOpen] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [lockLoading, setLockLoading] = useState(false);
  const [lockData, setLockData] = useState({});

  const handleGetLock = useCallback(loan_id => {
    if (!loan_id) return;
    setLockLoading(true);
    getSingleLoan(
      loan_id,
      true,
      res => {
        const data = res.data.loans[0].lock;
        Object.keys(data).forEach(key => {
          if (!Number.isNaN(Number(key))) delete data[key];
        });
        setLockData(data);
        setLockLoading(false);
        Helpers.notification.success(res.message);
      },
      err => {
        setLockLoading(false);
        Helpers.notification.error(err.message);
      }
    );
  }, [getSingleLoan]);

  // Add handlers for lock modal
  const handleLockModalOpen = loanId => {
    setSelectedLoanId(loanId);
    setGetLockModalOpen(true);
    handleGetLock(loanId);
  };

  const handleClearLock = useCallback(() => {
    if (!selectedLoanId) return;
    if (Number(lockData?.outstanding_amount) <= 0) {
      Helpers.notification.error('Outstanding amount is <= 0.');
      return;
    }
    if (Helpers.permission(['create-repayment'], permissions)) {
      clearLoanLock(
        selectedLoanId,
        res => {
          Helpers.notification.success(res.message);
          setClearLockModalOpen(false);
          setSelectedLoanId('null');
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-repayment]');
    }
  }, [clearLoanLock, lockData?.outstanding_amount, permissions, selectedLoanId]);

  return (
    <>
      <Button
        type="info"
        loading={item.loan_id === selectedLoanId && lockLoading}
        onClick={() => handleLockModalOpen(item.loan_id)}
      >
        Get Lock
      </Button>

      {/* Get Lock modal */}
      <SingleModal
        modalName="get_lock"
        modalOpen={getLockModalOpen}
        modalTitle="Lock Datails"
        defaultButtons={(
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              mt: 3,
            }}
          >
            <Button
              onClick={() => setGetLockModalOpen(false)}
              type="secondary"
            >
              Close
            </Button>
            {Helpers.permission(['create-repayment'], permissions) && Number(lockData?.outstanding_amount) > 0 && (
              <Button
                onClick={() => { setGetLockModalOpen(false); setClearLockModalOpen(true); }}
                type="success"
              >
                Clear Lock
              </Button>
            )}
          </Box>
          )}
        modalContent={
          lockLoading
            ? (
              <Loading size="small" />
            )
            : Object.keys(lockData).length > 0
              ? (
                <TableContainer component={Paper}>
                  <Table
                    className="modal-table"
                    sx={{
                      minHeight: 'fit-content',
                      minWidth: 650,
                      top: '50%',
                    }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell><b>KEY</b></TableCell>
                        <TableCell><b>VALUE</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(lockData).map(key => (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {key}
                          </TableCell>
                          <TableCell align="left">{JSON.stringify(lockData[key])}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
              : 'No lock data found.'
          }
        modalClose={() => setGetLockModalOpen(false)}
      />

      {/* Clear Lock modal */}
      <SingleModal
        modalName="clear_lock"
        modalOpen={clearLockModalOpen}
        modalTitle="Are you sure you want to clear this lock?"
        modalContent={(
          <Typography sx={{ whiteSpace: 'pre-line' }}>
            <b>
              {`loan_id: ${selectedLoanId};
              outstanding_amount: ${lockData?.outstanding_amount};`}
            </b>
          </Typography>
        )}
        defaultButtons={(
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              mt: 3,
            }}
          >
            <Button
              onClick={handleClearLock}
              type="success"
              loading={loadingClearLock}
            >
              Yes
            </Button>
            <Button
              onClick={() => setClearLockModalOpen(false)}
              type="secondary"
            >
              No
            </Button>
          </Box>
          )}
        modalClose={() => setClearLockModalOpen(false)}
      />
    </>
  );
}

ClearLockFt.props = {};

export default ClearLockFt;
