import React, {
  useState, useCallback, useEffect
} from 'react';
import {
  Modal,
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper
} from '@mui/material';
import Loading from '../../components/Loading'; // Assuming Loading component is imported from here
import * as Helpers from '../../helpers';

function RepaymentVerifier(props) {
  const [notRecordedData, setNotRecordedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const {
    clearAll,
    getRepaymentVerifier,
  } = props;

  const getRepaymentVerificationData = useCallback(
    (clear = true, rows = 10) => {
      if (clear) {
        clearAll();
      }
      getRepaymentVerifier(
        10,
        rows,
        res => {
          if (res?.data) {
            setNotRecordedData(res.data.notRecorded);
          } else {
            setNotRecordedData([]);
          }
          setLoading(false);
        },
        err => {
          Helpers.notification.error(err.message);
          setNotRecordedData([]);
          setLoading(false);
        }
      );
    },
    [clearAll, getRepaymentVerifier]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getRepaymentVerificationData(false, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getRepaymentVerificationData(false, 0, parseInt(event.target.value, 10));
  };

  useEffect(() => {
    setLoading(true);
    getRepaymentVerificationData();
  }, [getRepaymentVerificationData]);

  const formatDate = dateString => {
    const options = {
      day: 'numeric', hour: '2-digit', minute: '2-digit', month: 'long', second: '2-digit', timeZoneName: 'short', year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  // Function to handle modal display
  const handleShowModal = details => {
    setModalContent(details);
    setShowModal(true);
  };

  return (

    <Box
      component="main"
      sx={{ flexGrow: 1 }}
      style={{ maxWidth: '100%' }}
    >
      <Typography
        sx={{
          mb: 2, mt: 8,
        }}
      >
        REPAYMENT VERIFIER
      </Typography>
      {loading ? (
        <Loading size="big" />
      ) : (
        <Paper sx={{ overflow: 'hidden', width: '100%' }}>
          <TableContainer sx={{ maxHeight: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <caption>
                {notRecordedData.length === 0 ? (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      m: 2,
                    }}
                  >
                    <span>There are no unrecorded repayments.</span>
                    <Button variant="outlined" color="info" size="medium" onClick={() => window.location.reload()}>
                      Refresh
                    </Button>
                  </Box>
                ) : 'These are unrecorded repayments.'}
              </caption>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Amount Per Rate</TableCell>
                  <TableCell>Reference</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Paystack Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notRecordedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.customer.email}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{formatDate(item.createdAt)}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.rate}</TableCell>
                    <TableCell>{item.amountPerRate}</TableCell>
                    <TableCell>{item.reference}</TableCell>
                    <TableCell>{item.currency}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleShowModal(item.paystackDetails)}>View Details</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 2000]}
            component="div"
            count={notRecordedData.length} // Use the length of the data
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={{
              disabled: page >= Math.ceil(notRecordedData.length / rowsPerPage) - 1,
            }}
            backIconButtonProps={{
              disabled: page === 0,
            }}
          />
        </Paper>
      )}

      {showModal && (
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              maxHeight: '90vh',
              maxWidth: '600px',
              overflowY: 'auto',
              p: 4,
              width: '80%',
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
              Paystack Details
            </Typography>
            <pre id="modal-description" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {JSON.stringify(modalContent, null, 2)}
            </pre>
            <Button
              onClick={() => setShowModal(false)}
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
}

export default RepaymentVerifier;
