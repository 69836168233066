import React, {
  useState, useCallback, useMemo
} from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

import SingleModal from './SingleModal';
import Button from './Button';
import * as Helpers from '../helpers';

function RepayFt(props) {
  const {
    loading, history, getHistoryData, permissions, rates, repay,
  } = props;

  const [loanID, setLoanID] = useState(0);
  const [loanIdDisplay, setLoanIdDisplay] = useState(`loan_id: ${0}`);

  // currency input
  const currency = useMemo(() => ({ Cents: 'USD', Kobo: 'NGN' }), []);
  const [repayCurrencyDisplay, setrepayCurrencyDisplay] = useState('Cents');
  const [currencyAnchor, setCurrencyAnchor] = useState(null);
  const currencyOpen = Boolean(currencyAnchor);

  const handleCurrencyInput = useCallback(event => {
    setCurrencyAnchor(event.currentTarget);
  }, []);
  const handleCurrencyClose = useCallback(() => {
    setCurrencyAnchor(null);
  }, []);
  const handleCurrencyValue = useCallback(e => {
    setrepayCurrencyDisplay(e.target.textContent);
  }, []);

  // amount to repay
  const [repayAmount, setRepayAmount] = useState({
    value: 0,
  });
  const handleAmountInput = useCallback(event => {
    setRepayAmount({
      value: event.target.value,
    });
  }, []);

  // repay modal
  const loadingRepay = useMemo(() => loading.some(url => url === '/admin/repay'), [loading]);
  const [repayStep, setRepayStep] = useState(1);
  const handleSetRepayStep = useCallback(step => {
    setRepayStep(step || 2);
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => setModalOpen(true), []);
  const handleModalClose = useCallback(() => {
    setLoanID(0);
    setLoanIdDisplay(`loan_id: ${0}`);
    setRepayAmount({
      value: 0,
    });
    handleSetRepayStep(1);
    setModalOpen(false);
  }, [handleSetRepayStep]);

  // loanId to repay
  const handleLoanIdInput = useCallback(event => {
    if (Helpers.permission(['create-repayment-loan.id'], permissions)) {
      setLoanID(event.target.value.split(' ')[1]);
      setLoanIdDisplay(`loan_id: ${event.target.value.split(' ')[1] || ''}`);
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-repayment-loan.id]');
    }
  }, [permissions]);

  // submit repay
  const handleAmountSubmit = useCallback(event => {
    event.preventDefault();
    let { value } = repayAmount;
    const repayCurrency = currency[repayCurrencyDisplay];
    value = repayCurrency === 'NGN' ? value / rates.buy : value;
    const excess = value > history.user[0]?.info.total.currentDebt;
    if (Helpers.permission(['create-repayment'], permissions) && !excess) {
      if (value > 0) {
        handleSetRepayStep(2);
      }
    } else {
      const error = Helpers.permission(['create-repayment'], permissions)
        ? (
          excess ? 'You cannot repay more than Total Debt.' : ''
        )
        : 'You do not have the necessary permission(s): [create-repayment]';
      Helpers.notification.error(error);
    }
  }, [currency, handleSetRepayStep, history.user, permissions, rates.buy, repayAmount, repayCurrencyDisplay]);

  const handleRepay = useCallback(() => {
    const amount = parseInt(repayAmount.value, 10);
    const { user_id } = history.user[0];
    const repayCurrency = currency[repayCurrencyDisplay];
    repay(
      amount,
      user_id,
      repayCurrency,
      loanID || 0,
      successRes => {
        Helpers.notification.success(successRes.message);
        getHistoryData();
      },
      errorRes => {
        Helpers.notification.error(errorRes.message);
      }
    );
    setLoanID(0);
    setLoanIdDisplay(`loan_id: ${0}`);
    setRepayAmount({
      value: 0,
    });
    handleSetRepayStep(1);
    handleModalClose();
  }, [currency, getHistoryData, handleModalClose, handleSetRepayStep, history.user, loanID, repay, repayAmount.value, repayCurrencyDisplay]);

  return (
    <>
      <Box
        sx={{
          alignSelf: 'end',
          display: 'block',
          m: '24px',
        }}
      >
        <Button
          type="info"
          loading={loadingRepay}
          onClick={handleModalOpen}
        >
          Repay
        </Button>
      </Box>

      {/* repay modal */}
      <SingleModal
        modalName="repay"
        modalOpen={modalOpen}
        modalTitle={
          repayStep === 1
            ? 'How much do you want to repay for this user?'
            : `
              Are you sure you want to repay
              ${repayAmount.value}
              ${repayCurrencyDisplay}
              ${loanID ? ` into loan_id ${loanID},` : ''}
              for this user?
          `
        }
        modalContent={
          repayStep === 1
            ? Helpers.permission(['create-repayment-loan.id'], permissions)
              ? (
                <>
                  <form
                    className="currencyForm"
                  >
                    <ArrowDropDownIcon
                      className="singleUserDropdown"
                      id="basic-button"
                      aria-controls={currencyOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={currencyOpen ? 'true' : undefined}
                      onClick={handleCurrencyInput}
                    />

                    <Menu
                      id="basic-menu"
                      anchorEl={currencyAnchor}
                      open={currencyOpen}
                      onClose={handleCurrencyClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleCurrencyValue}>Cents</MenuItem>
                      <MenuItem onClick={handleCurrencyValue}>Kobo</MenuItem>
                    </Menu>

                    <Typography
                      className="singleUserCurrencyDisplay"
                      sx={{
                        fontSize: '20px',
                      }}
                      onClick={handleCurrencyInput}
                    >
                      {repayCurrencyDisplay}
                    </Typography>
                  </form>

                  <input
                    className="loanIdInput"
                    placeholder="Enter loan ID"
                    name="loanIdInput"
                    value={loanIdDisplay}
                    onChange={handleLoanIdInput}
                    autoComplete="off"
                  />
                </>
              )
              : (
                <form
                  className="currencyForm"
                >
                  <ArrowDropDownIcon
                    className="singleUserDropdown"
                    id="basic-button"
                    aria-controls={currencyOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={currencyOpen ? 'true' : undefined}
                    onClick={handleCurrencyInput}
                  />

                  <Menu
                    id="basic-menu"
                    anchorEl={currencyAnchor}
                    open={currencyOpen}
                    onClose={handleCurrencyClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleCurrencyValue}>Cents</MenuItem>
                    <MenuItem onClick={handleCurrencyValue}>Kobo</MenuItem>
                  </Menu>

                  <Typography
                    className="singleUserCurrencyDisplay"
                    sx={{
                      fontSize: '20px',
                    }}
                    onClick={handleCurrencyInput}
                  >
                    {repayCurrencyDisplay}
                  </Typography>
                </form>
              )
            : ''
        }
        modalInput={repayStep === 1}
        inputPlaceholder={`Enter amount in ${repayCurrencyDisplay}`}
        inputValue={repayAmount.value}
        onInputChange={handleAmountInput}
        actionButtonText={repayStep === 1 ? 'Submit' : 'Yes'}
        actionButtonDisabled={repayAmount.value < 1}
        modalSubmit={repayStep === 1 ? handleAmountSubmit : handleRepay}
        modalClose={handleModalClose}
      />
    </>
  );
}

RepayFt.props = {};

export default RepayFt;
