import React, {
  useEffect, useState, useCallback, useMemo
} from 'react';
import sha256 from 'sha256';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button as MuiButton,
  TextField,
  TablePagination
} from '@mui/material';

import {
  Loading, Button, SingleModal, UserCard, BlacklistFt, ChargeUserFt, RepayFt, ClearLockFt, CompleteWithdrawalFt, ApproveWithdrawalFt, SearchAppBar
} from '../../components';
import * as Helpers from '../../helpers';
import AccountTable from './AccountTable';

import './History.css';

function History(props) {
  const {
    getHistory,
    admin: {
      loading, permissions, rates,
    },
    match: { params: { table, id } },
    reject,
    refresh,
    issueLoan,
    updateRepayment,
    history: navHistory,
    repay,
    completeWithdrawal,
    blacklist,
    getRates,
    chargeUser,
    approveWithdrawal,
    getUserBreakdown,
    waitlist,
    getSingleLoan,
    clearLoanLock,
    getAccount,
    approveAccount,
  } = props;

  const [actionID, setActionID] = useState(0);

  const [allLoansbreakdown, setAllLoansbreakdown] = useState([]);
  const [page, setPage] = useState({
    default: 0,
    loan: 0,
    portfolio: 0,
    repayment: 0,
    user: 0,
    withdrawal: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState({
    default: 10,
    loan: 10,
    portfolio: 10,
    repayment: 10,
    user: 10,
    withdrawal: 10,
  });
  const [history, setHistory] = useState({
    loan: [],
    portfolio: [],
    repayment: [],
    user: [],
    withdrawal: [],
  });

  const [total, setTotal] = useState({
    loan: 0,
    portfolio: 0,
    repayment: 0,
    user: 0,
    withdrawal: 0,
  });
  const [blacklistActions, setBlacklistActions] = useState({
    loan: false,
    repayment: false,
    signin: false,
    withdrawal: false,
  });

  const loadingHistory = useMemo(() => loading.some(url => url === `/admin/history/${table}/${id}?type=${'loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'}${(rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'] !== null && (page['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'] * rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal']) !== null) ? `&limit=${rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal']}&offset=${page['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'] * rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal']}` : ''}`), [id, loading, page, rowsPerPage, table]);

  const getUserBreakdownData = useCallback(user_id => {
    if (user_id) {
      getUserBreakdown(
        user_id,
        res => {
          Helpers.notification.success(res.message);
          // eslint-disable-next-line no-console
          // console.log('user-breakdown ', res.data.breakdown);
          setAllLoansbreakdown(res.data.breakdown);
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    }
  }, [getUserBreakdown]);

  const getHistoryData = useCallback(() => {
    ['loan', 'portfolio', 'repayment', 'user', 'withdrawal'].forEach(tab => {
      getHistory(
        table,
        id,
        tab,
        rowsPerPage[tab],
        page[tab] * rowsPerPage[tab],
        res => {
          setHistory(prev => ({ ...prev, [tab]: res.data[tab].all }));
          setTotal(prev => ({ ...prev, [tab]: res.data[tab].total }));
          if (tab === 'user') {
            getUserBreakdownData(res.data.user.all[0].user_id);
            const actions = res.data.user.all[0]?.blacklist?.actions;
            if (actions) {
              setBlacklistActions(actions);
            }
          }
          // eslint-disable-next-line no-console
          console.log(`${tab}-history:`, res.data[tab].all);
        }
      );
    });
  }, [getHistory, getUserBreakdownData, id, page, rowsPerPage, table]);

  useEffect(() => {
    getHistoryData();
    getRates();
  }, [getRates, getHistoryData]);

  history.portfolio.map((item, i) => {
    const hash = sha256(item.loan_id || '').substring(0, 8);
    history.portfolio[i] = {
      'portfolio hash id': hash, ...item, refresh: true, waitlist_toggle: true,
    };
    return null;
  });

  history.loan.map((item, i) => {
    let tenureDataObject = {};
    if (item.disbursed && Number(item.tenure) > 0) {
      const disbursedDate = new Date(item.disbursed);
      disbursedDate.setMonth(disbursedDate.getMonth() + 1);
      const startMonthText = disbursedDate.toLocaleString('en-US', { month: 'long' });
      const startYear = disbursedDate.getFullYear();

      disbursedDate.setMonth(disbursedDate.getMonth() + (Number(item.tenure) - 1));
      const endMonthText = disbursedDate.toLocaleString('en-US', { month: 'long' });
      const endYear = disbursedDate.getFullYear();

      tenureDataObject = {
        end_date: `${endMonthText}, ${endYear}`,
        monthly_principal: (item.principal / item.tenure).toFixed(2),
        start_date: `${startMonthText}, ${startYear}`,
      };
    }
    const hash = sha256(item.loan_id || '').substr(0, 8);
    history.loan[i] = {
      'loan hash id': hash,
      ...item,
      tenure_data: tenureDataObject,
      ...(Helpers.permission(['update-loan-status-issue'], permissions) ? { issue: true } : {}),
      ...(Helpers.permission(['update-loan-status-reject'], permissions) ? { reject: true } : {}),
      get_lock: true,
    };
    return null;
  });

  history.withdrawal.map((item, i) => {
    const hash = sha256(item.withdrawal_id || '').substr(0, 8);
    history.withdrawal[i] = { 'withdrawal hash id': hash, ...item, action: true };
    return null;
  });

  history.repayment.map((item, i) => {
    const hash = sha256(item.repayment_id || '').substr(0, 8);
    history.repayment[i] = { 'repayment hash id': hash, ...item, update: true };
    return null;
  });

  const formatDate = formattableDate => {
    let day = '';
    let month = '';
    let year = '';
    let hours = '';
    let seconds = '';
    let minutes = '';
    let date = new Date(formattableDate);
    if (date.getTime() <= new Date().getTime()) {
      date = moment(date, 'MM/DD/YYYY').add(1, 'M').toDate();
    }
    day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    year = date.getFullYear();
    hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const spreadArray = compactArr => {
    const spreadArr = JSON.parse(JSON.stringify(compactArr));
    return spreadArr.map(obj => {
      const newObj = { ...obj };
      const keys = Object.keys(obj);
      keys.forEach(key => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const innerKeys = Object.keys(obj[key]);
          innerKeys.forEach(innerKey => {
            if (typeof obj[key][innerKey] === 'object' && obj[key][innerKey] !== null) {
              const innerInnerKeys = Object.keys(obj[key][innerKey]);
              innerInnerKeys.forEach(innerInnerKey => {
                const val = `${innerKey}_${innerInnerKey}`;
                newObj[val] = obj[key][innerKey][innerInnerKey];
              });
              delete newObj[innerKey];
            }
            newObj[`${key}_${innerKey}`] = obj[key][innerKey];
          });
          delete newObj[key];
        }
      });
      return { ...newObj };
    });
  };

  // ------------------- start refresh --------------------- //
  const loadingRefresh = useMemo(() => loading.some(url => url === '/admin/portfolio'), [loading]);
  const refreshPortfolio = useCallback((_id, _user, application_id) => () => {
    setActionID(_id);
    refresh(
      _user,
      application_id,
      res => {
        Helpers.notification.success(res.message);
        getHistoryData();
      },
      err => {
        Helpers.notification.error(err.message);
      }

    );
  }, [getHistoryData, refresh]);
  // ------------------- stop refresh --------------------- //

  // ------------------- start waitlist --------------------- //
  const loadingWaitlistToggle = useMemo(() => loading.some(url => url === '/admin/waitlist'), [loading]);
  const waitlistToggle = useCallback((_id, status) => () => {
    setActionID(_id);
    const newStatus = status === '0' ? '1' : '0';
    waitlist(
      _id,
      newStatus,
      res => {
        Helpers.notification.success(res.message);
        getHistoryData();
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
  }, [getHistoryData, waitlist]);
  // ------------------- stop waitlist --------------------- //

  // ------------------- start reject --------------------- //
  const loadingReject = useMemo(() => loading.some(url => url === '/admin/reject'), [loading]);

  const rejectLoan = useCallback(_id => () => {
    setActionID(_id);

    if (Helpers.permission(['update-loan-status-reject'], permissions)) {
      reject(
        _id,
        res => {
          Helpers.notification.success(res.message);
          getHistoryData();
        },
        err => {
          Helpers.notification.success(err.message);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-loan-status-reject]');
    }
  }, [permissions, getHistoryData, reject]);
  // ------------------- stop reject --------------------- //

  // ------------------- start issue --------------------- //
  const loadingIssue = useMemo(() => loading.some(url => url === '/admin/issueLoan'), [loading]);

  const loanIssue = useCallback(_id => () => {
    setActionID(_id);
    if (Helpers.permission(['update-loan-status-issue'], permissions)) {
      issueLoan(
        _id,
        res => {
          getHistoryData();
          Helpers.notification.success(res.message);
        },
        err => {
          Helpers.notification.error(err);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-loan-status-issue]');
    }
  }, [permissions, getHistoryData, issueLoan]);
  // ------------------- stop issue --------------------- //

  // ------------------- start repayment update --------------------- //
  const loadingUpdate = useMemo(() => loading.some(url => url === '/admin/repayment/update'), [loading]);

  const [repaymentValues, setRepaymentValues] = useState({});
  const handleSetRepaymentValues = useCallback((e, key, repayment_id) => {
    setRepaymentValues({
      ...repaymentValues,
      [key]: e.target.value,
      repayment_id,
    });
  }, [repaymentValues]);

  const [repaymentModalOpen, setRepaymentModalOpen] = useState(false);
  const handleRepaymentModalOpen = useCallback(() => setRepaymentModalOpen(true), []);
  const handleRepaymentModalClose = useCallback(() => {
    setRepaymentValues({});
    setRepaymentModalOpen(false);
  }, []);

  const handleRepaymentUpdateSubmit = useCallback((event, repayment_id) => {
    event.preventDefault();
    setActionID(repayment_id);
    // check if repayment time was over one hr ago
    // const currentTime = new Date();
    // const repaymentDate = new Date(date);
    // const elapsedHours = Math.ceil(Math.abs(currentTime - repaymentDate) / (60 * 60 * 1000));
    // if (elapsedHours <= 3) {
    if (Object.keys(repaymentValues).length !== 0) {
      if (Helpers.permission(['update-repayment-principal.interest', 'update-repayment-date'], permissions)) {
        handleRepaymentModalOpen();
      } else {
        Helpers.notification.error('You do not have the necessary permission(s): [update-repayment-principal.interest, update-repayment-date]');
      }
    } else {
      Helpers.notification.error('No updates were made');
    }
    // } else {
    // Helpers.notification.error('Not Allowed! Repayment was made over 3 hours ago');
    // }
  }, [permissions, handleRepaymentModalOpen, repaymentValues]);

  const repaymentUpdate = useCallback(() => {
    const {
      date,
      interest,
      principal,
      repayment_id,
    } = repaymentValues;
    if (actionID === repayment_id) {
      updateRepayment(
        actionID,
        principal,
        interest,
        date,
        res => {
          getHistoryData();
          Helpers.notification.success(res.message);
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    } else {
      Helpers.notification.error('Incorrect update attempt');
    }
    handleRepaymentModalClose();
  }, [actionID, getHistoryData, handleRepaymentModalClose, repaymentValues, updateRepayment]);
  // ------------------- stop repayment update --------------------- //

  // -------------------start pagination------------------------ //
  const handleChangePage = useCallback(tableName => (_, newPage) => {
    setPage({ ...page, [tableName]: newPage });
    // getHistoryData();
  }, [page]);

  const handleChangeRowsPerPage = useCallback(tableName => e => {
    setRowsPerPage({ ...rowsPerPage, [tableName]: +e.target.value });
    setPage({ ...page, [tableName]: 0 });
    // getHistoryData();
  }, [page, rowsPerPage]);
  // ------------------- stop pagination ------------------------ //

  return (
    <>
      <SearchAppBar back={navHistory.goBack} buttonName="back" />

      {loadingHistory ? <Loading size="big" /> : (
        <div>
          {/* User Card */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <UserCard history={history} rates={rates} formatDate={formatDate} />

            {Helpers.permission(['update-user-blacklist'], permissions) && (
              <BlacklistFt
                blacklistActions={blacklistActions}
                setBlacklistActions={setBlacklistActions}
                loading={loading}
                history={history}
                blacklist={blacklist}
                getHistoryData={getHistoryData}
                permissions={permissions}
                id={id}
              />
            )}

            {Helpers.permission(['create-repayment-card'], permissions) && (
              <ChargeUserFt
                loading={loading}
                history={history}
                getHistoryData={getHistoryData}
                permissions={permissions}
                id={id}
                chargeUser={chargeUser}
              />
            )}

            {Helpers.permission(['create-repayment'], permissions) && (
              <RepayFt
                loading={loading}
                history={history}
                getHistoryData={getHistoryData}
                permissions={permissions}
                repay={repay}
                rates={rates}
              />
            )}

            {Helpers.permission(['read-loan-breakdown'], permissions) && (
              <Box
                sx={{
                  alignSelf: 'end',
                  display: 'block',
                  m: '24px',
                }}
              >
                <CSVLink
                  data={allLoansbreakdown}
                  filename={`${history.user[0]?.email}-loan-breakdown.csv`}
                  className="btn btn-primary csvButton"
                >
                  {Helpers.capitalizeFirstLetter('Export Breakdown')}
                </CSVLink>
              </Box>
            )}
          </Box>

          {/* the three tables */}
          {
            ['portfolio', 'loan', 'withdrawal', 'repayment'].map((tableName, m) => (
              <Box key={m}>
                <Paper
                  sx={{
                    marginLeft: '2.5%', overflow: 'hidden', width: '95%',
                  }}
                >
                  <TableContainer sx={{ maxHeight: '85vh' }}>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Typography
                        sx={{
                          flex: '1 1 100%',
                          p: 2,
                        }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        {Helpers.capitalizeFirstLetter(tableName)}
                      </Typography>

                      {
                      history[tableName].length !== 0
                        ? (
                          <Box
                            sx={{
                              display: 'block',
                              m: 2,
                            }}
                          >
                            <CSVLink
                              data={spreadArray(history[tableName], tableName)}
                              filename={`${history.user[0]?.email}-${tableName}.csv`}
                              className="btn btn-primary csvButton"
                            >
                              {Helpers.capitalizeFirstLetter('Export as CSV')}
                            </CSVLink>
                          </Box>
                        )
                        : ''
                      }
                    </Box>
                    <Table stickyHeader aria-label="sticky table">
                      <caption>
                        {
                          history[tableName].length === 0
                            ? (
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  m: 2,
                                }}
                              >
                                <span>
                                  There are no
                                  {' '}
                                  {tableName}
                                  s for the selected user
                                  {' '}
                                  {id}
                                  .
                                </span>
                                <MuiButton variant="outlined" color="info" size="medium" onClick={() => window.location.reload()}>
                                  Refresh
                                </MuiButton>
                              </Box>
                            ) : `These are ${tableName}s for the user ${id} only.`
                        }
                      </caption>
                      <TableHead>
                        <TableRow>
                          {history[tableName].length === 0 ? null : Object.keys(history[tableName][0]).map((key, ii) => {
                            let heading = key;
                            heading = key === 'info' ? 'debt' : heading;
                            const removeColumn = (tableName !== 'repayment' && key === 'raw') || (tableName !== 'withdrawal' && key === 'details') || key === 'metadata';

                            if (removeColumn) return null;
                            if (tableName === 'repayment' && key === 'raw') heading = 'author';
                            return (
                              <TableCell
                                key={ii}
                                align="center"
                                style={{ minWidth: 150 }}
                              >
                                {Helpers.capitalizeFirstLetter(heading)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {history[tableName].map((item, i) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {Object.keys(history[tableName][0]).map((key, j) => {
                              let val = item[key];
                              const exempted = (key === 'card' || key === 'code' || key === 'password' || key === 'token' || key === 'legal');
                              const isUserStatus = tableName === 'user' && key === 'status';
                              const removeColumn = (tableName !== 'repayment' && key === 'raw') || (tableName !== 'withdrawal' && key === 'details') || key === 'metadata';
                              let noShow = null;
                              if (tableName === 'portfolio' && !Helpers.permission(['read-portfolio-full'], permissions)) noShow = key === 'amount';
                              if (tableName === 'withdrawal' && !Helpers.permission(['read-withdrawal-full'], permissions)) noShow = key === 'details';
                              if (tableName === 'repayment' && !Helpers.permission(['read-repayment-full'], permissions)) noShow = key === 'reference';

                              if (removeColumn) return null;
                              if (noShow) {
                                return (
                                  <TableCell key={j} align="center" title="You do not have the necessary permission(s)">
                                    NP
                                  </TableCell>
                                );
                              }
                              if (tableName === 'repayment' && key === 'raw') val = val.author;
                              if (key === 'breakdown') {
                                val = `Future Debt (Date): ${val.future.date ? formatDate(val.future.date) : '-'}\n Future Debt (Total): ${val.future.amount}\n Future Debt (Interest): ${val.future.interest}\n Future Debt (Principal): ${val.future.principal}\n\n\n Current Debt (Date): ${val.current.date ? formatDate(val.current.date) : '-'}\n Current Debt (Total): ${val.current.amount}\n Current Debt (Interest): ${val.current.interest}\n Current Debt (Principal): ${val.current.principal}\n `;
                              }
                              if (key === 'tenure_data') {
                                val = val.start_date ? `Monthly principal: ${val.monthly_principal};\n Start Date: ${val.start_date};\n End date: ${val.end_date}` : null;
                              }
                              return (
                                <TableCell key={j} align="center" title={typeof val === 'object' || typeof val === 'boolean' ? '' : val}>
                                  {
                                  ((tableName === 'loan' && key === 'reject') || (tableName === 'loan' && key === 'issue') || (tableName === 'portfolio' && (key === 'refresh' || key === 'waitlist_toggle')))
                                    ? (
                                      tableName === 'portfolio' || (key === 'reject' && item.status === 'issued') || (key === 'issue' && item.status === 'requested')
                                        ? (
                                          <Button
                                            type={tableName === 'loan' && key === 'reject' ? 'error' : 'info'}
                                            loading={(item.loan_id === actionID && loadingReject) || (item.loan_id === actionID && loadingIssue) || (item.user_id === actionID && loadingRefresh) || (item.portfolio_id === actionID && loadingWaitlistToggle)}
                                            onClick={tableName === 'loan' && key === 'reject' ? rejectLoan(item.loan_id) : tableName === 'loan' && key === 'issue' ? loanIssue(item.loan_id) : (tableName === 'portfolio' && key === 'refresh') ? refreshPortfolio(item.portfolio_id, item.user_id, item.application_id) : (tableName === 'portfolio' && key === 'waitlist_toggle') ? waitlistToggle(item.portfolio_id, item.waitlist) : () => {}}
                                          >
                                            {tableName === 'loan' && key === 'reject' ? 'Reject' : tableName === 'loan' && key === 'issue' ? 'Issue' : tableName === 'portfolio' && key === 'waitlist_toggle' ? `${item.waitlist}` : 'Refresh'}
                                          </Button>
                                        )
                                        : 'N/A'
                                    )
                                    // repayment update
                                    : (Helpers.permission(['update-repayment-principal.interest', 'update-repayment-date'], permissions) && tableName === 'repayment' && (key === 'principal' || key === 'interest' || key === 'date'))
                                      ? (
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          name=""
                                          defaultValue={val}
                                          onChange={e => handleSetRepaymentValues(e, key, item.repayment_id)}
                                        />
                                      )
                                      : (tableName === 'repayment' && key === 'update')
                                        ? Helpers.permission(['update-repayment-principal.interest', 'update-repayment-date'], permissions)
                                          ? (
                                            <Button
                                              type="info"
                                              loading={item.repayment_id === actionID && loadingUpdate}
                                              onClick={e => handleRepaymentUpdateSubmit(e, item.repayment_id)}
                                            >
                                              Update
                                            </Button>
                                          )
                                          : 'N/A'
                                        : (tableName === 'withdrawal' && key === 'action')
                                          ? (Helpers.permission(['update-withdrawal-status-complete'], permissions) && !item.approved && !(item.rejected || item.status === 'rejected'))
                                            ? (
                                              <ApproveWithdrawalFt
                                                loading={loading}
                                                getHistoryData={getHistoryData}
                                                permissions={permissions}
                                                item={item}
                                                actionID={actionID}
                                                setActionID={setActionID}
                                                approveWithdrawal={approveWithdrawal}
                                              />
                                            )
                                            : (Helpers.permission(['update-withdrawal-status-complete'], permissions) && item.status === 'processing' && item.processor_id === '3')
                                              ? (
                                                <CompleteWithdrawalFt
                                                  loading={loading}
                                                  getHistoryData={getHistoryData}
                                                  permissions={permissions}
                                                  item={item}
                                                  actionID={actionID}
                                                  setActionID={setActionID}
                                                  completeWithdrawal={completeWithdrawal}
                                                />
                                              )
                                              : 'N/A'
                                          : (tableName === 'loan' && key === 'get_lock')
                                            ? (
                                              <ClearLockFt
                                                getSingleLoan={getSingleLoan}
                                                item={item}
                                                permissions={permissions}
                                                clearLoanLock={clearLoanLock}
                                                loading={loading}
                                              />
                                            )
                                            : isUserStatus && val
                                              ? 'ACTIVE'
                                              : isUserStatus && !val
                                                ? 'INACTIVE'
                                                : exempted && !!val
                                                  ? 'YES'
                                                  : exempted && !val
                                                    ? 'NO'
                                                    : typeof val === 'object'
                                                      ? JSON.stringify(val)
                                                      : val
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 2000]}
                    component="div"
                    count={Number(total[tableName])}
                    rowsPerPage={rowsPerPage[tableName]}
                    page={history[tableName].length <= 0 ? 0 : page[tableName]}
                    onPageChange={handleChangePage(tableName)}
                    onRowsPerPageChange={handleChangeRowsPerPage(tableName)}
                  />
                </Paper>
                <br />
                <br />
                <br />
              </Box>
            ))
          }
          <AccountTable
            id={id}
            permissions={permissions}
            loading={loading}
            getAccount={getAccount}
            approveAccount={approveAccount}
            spreadArray={spreadArray}
            userEmail={history.user[0]?.email}
            getHistoryData={getHistoryData}
          />

          <Box
            sx={{
              display: Helpers.permission(['create-repayment'], permissions) ? 'block' : 'none',
              textAlign: 'left',
            }}
          >
            {/* repayment update modal */}
            <div>
              <SingleModal
                modalName="updateRepayment"
                modalOpen={repaymentModalOpen}
                modalTitle={
                  `
                    Are you sure you want make these repayment updates for this user?
                    \n
                    ${repaymentValues.repayment_id ? `repayment_id: ${repaymentValues.repayment_id}` : ''}
                    \n
                    ${repaymentValues.principal ? `principal: ${repaymentValues.principal}` : ''}
                    \n
                    ${repaymentValues.interest ? `interest: ${repaymentValues.interest}` : ''}
                    \n
                    ${repaymentValues.date ? `date: ${repaymentValues.date}` : ''}
                  `
                }
                modalSubmit={repaymentUpdate}
                modalClose={handleRepaymentModalClose}
              />
            </div>
          </Box>
          <br />
          <br />
          <br />
        </div>
      )}
    </>
  );
}

export default History;
