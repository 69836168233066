import React, {
  useState, useCallback, useEffect
} from 'react';
import sha256 from 'sha256';
import { CSVLink } from 'react-csv';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button as MuiButton,
  TablePagination
} from '@mui/material';

import {
  ApproveAccountFt
} from '../../components';
import * as Helpers from '../../helpers';

const tableName = 'account';
function AccountTable(props) {
  const {
    id, permissions, loading, approveAccount, spreadArray, userEmail, getAccount,
    getHistoryData,
  } = props;

  const [actionID, setActionID] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState({
    all: [],
    total: 0,
  });

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(e => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }, []);

  const getTableData = useCallback(() => {
    getAccount(
      id,
      null, // status
      rowsPerPage,
      page * rowsPerPage,
      res => {
        setTableData({ all: res.data.all, total: res.data.total });
        // eslint-disable-next-line no-console
        console.log(`${tableName}-history:`, res.data.all);
      }
    );
  }, [getAccount, id, page, rowsPerPage]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  tableData.all.map((item, i) => {
    const hash = sha256(item.account_id || '').substr(0, 8);
    tableData.all[i] = {
      'account hash id': hash,
      ...item,
      approve: true,
    };
    return null;
  });

  return (
    <Box>
      <Paper
        sx={{
          marginLeft: '2.5%', overflow: 'hidden', width: '95%',
        }}
      >
        <TableContainer sx={{ maxHeight: '85vh' }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                flex: '1 1 100%',
                p: 2,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {Helpers.capitalizeFirstLetter(tableName)}
            </Typography>

            {
            tableData.all.length !== 0
              ? (
                <Box
                  sx={{
                    display: 'block',
                    m: 2,
                  }}
                >
                  <CSVLink
                    data={spreadArray(tableData.all, tableName)}
                    filename={`${userEmail}-${tableName}.csv`}
                    className="btn btn-primary csvButton"
                  >
                    {Helpers.capitalizeFirstLetter('Export as CSV')}
                  </CSVLink>
                </Box>
              )
              : ''
            }
          </Box>
          <Table stickyHeader aria-label="sticky table">
            <caption>
              {
                tableData.all.length === 0
                  ? (
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        m: 2,
                      }}
                    >
                      <span>
                        There are no
                        {' '}
                        {tableName}
                        s for the selected user
                        {' '}
                        {id}
                        .
                      </span>
                      <MuiButton variant="outlined" color="info" size="medium" onClick={() => window.location.reload()}>
                        Refresh
                      </MuiButton>
                    </Box>
                  ) : `These are ${tableName}s for the user ${id} only.`
              }
            </caption>
            <TableHead>
              <TableRow>
                {tableData.all.length === 0 ? null : Object.keys(tableData.all[0]).map((key, ii) => {
                  const heading = key;
                  const removeColumn = key === 'account_number' || key === 'bank_code';
                  if (removeColumn) return null;
                  return (
                    <TableCell
                      key={ii}
                      align="center"
                      style={{ minWidth: 150 }}
                    >
                      {Helpers.capitalizeFirstLetter(heading)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.all.map((item, i) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {Object.keys(tableData.all[0]).map((key, j) => {
                    const val = item[key];

                    const removeColumn = (key === 'account_number') || (key === 'bank_code');
                    if (removeColumn) return null;

                    let noShow = null;
                    if (!Helpers.permission(['update-account-status'], permissions)) noShow = (key === 'approve');
                    if (noShow) {
                      return (
                        <TableCell key={j} align="center" title="You do not have the necessary permission(s)">
                          NP
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={j} align="center" title={typeof val === 'object' || typeof val === 'boolean' ? '' : val}>
                        {
                        (key === 'approve')
                          ? (item.status === 'pending')
                            ? (
                              <ApproveAccountFt
                                loading={loading}
                                permissions={permissions}
                                approveAccount={approveAccount}
                                getHistoryData={getHistoryData}
                                item={item}
                                actionID={actionID}
                                setActionID={setActionID}
                              />
                            )
                            : 'N/A'
                          : typeof val === 'object'
                            ? JSON.stringify(val)
                            : val
                        }
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 2000]}
          component="div"
          count={Number(tableData.total)}
          rowsPerPage={rowsPerPage}
          page={tableData.all.length <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default AccountTable;
